<template>
  <div class="box">
        <div :style="'height:'+ pmHeight + 'px;overflow: scroll;padding-top: 15px;box-sizing: border-box;'" class="msgline">
            <div v-for="item in chatMsgList" :key="item.id" class="list-box">
                <div class="left-chat-box" v-if="(item.fromId && item.toId) == localFromId">
                    <img class="headimg" :src="item.users.headimgurl" alt="">
                    <div class="left-chat-msg">{{item.msg}}</div>
                </div>
                <div class="right-chat-box" v-else>
                    <div class="right-chat-msg">{{item.msg}}</div>
                    <img class="headimg" :src="item.users.headimgurl" alt="">
                </div>
            </div>
            <div v-if="chatMsgList.length==0 && !isLoading" style="margin-top:30px;text-align:center;">暂无沟通记录</div>
        </div>
        <div class="msg-content">
                <textarea class="texta" placeholder="请输入消息内容" v-model="msg"></textarea>
            <div class="send" @click="sendMsg">发送</div>
        </div>
        <loading :show="isLoading" :msg="'加载中...'"></loading>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import {Toast} from 'vant'
import loading from "./comm/loading";
// import * as imageConversion from 'image-conversion'
import { passenger } from "@/api";
export default {
  components: {
    loading,
  },
  data() {
      return {
        msg: "",
        toId: "",
        localFromId: "",
        pmHeight: 0,
        isLoading: false,
        chatMsgList: [
            {
                id:1,
                msg: '你好',
                time:'2022-12-13 10:30:08',
                name: '🦄 荻野',
                type: 'me',
                headimg: 'https://thirdwx.qlogo.cn/mmopen/vi_32/I9pOtx2UVsgKWfZBGeGiaY0a3leo7Llcz6ZMs5TZDN0E14JAcPoLEXyGuoZO2qqJLA1vAKP0ufCG9js4FHOII0A/132'
            },
            {
                id:2,
                msg: '今天下午时间还不确定，我等下问一下在回复你',
                time:'2022-12-13 10:35:31',
                name: '付小七',
                type: 'you',
                headimg: 'https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIx3CKOmlYc4fn1A7TwcYxJrdoJ0iapRzX5KBFaCYmjknPExRx5EhDnrwPe192dmxub2Ood8UicSG8g/132'
            },
            {
                id:1,
                msg: '好的，我等你的消息',
                time:'2022-12-13 10:30:08',
                name: '🦄 荻野',
                type: 'me',
                headimg: 'https://thirdwx.qlogo.cn/mmopen/vi_32/I9pOtx2UVsgKWfZBGeGiaY0a3leo7Llcz6ZMs5TZDN0E14JAcPoLEXyGuoZO2qqJLA1vAKP0ufCG9js4FHOII0A/132'
            },
            {
                id:2,
                msg: '不好意思，今天下午去不了，因为我临时有事情要处理，取消了这个行程，实在不好意思了',
                time:'2022-12-13 10:35:31',
                name: '付小七',
                type: 'you',
                headimg: 'https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTIx3CKOmlYc4fn1A7TwcYxJrdoJ0iapRzX5KBFaCYmjknPExRx5EhDnrwPe192dmxub2Ood8UicSG8g/132'
            },
        ],
        timer: null
      }
  },
  filters: {
      formatDateTime(date) {
          return date !== undefined && date !== null
              ? date.substring(0, 16)
              : "";
      },
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  mounted() {
      document.title = "期望同行";
      this.localFromId = JSON.parse(localStorage.getItem('user')).id
      this.toId = this.$route.query.toId
      this.pmHeight =(window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 60;
      this.toDay = dayjs().format('YYYY年MM月DD日')
      this.isLoading = true
      clearInterval(this.timer)
      setTimeout(() => {
        this.getChatList()
      }, 1000);
      this.timer = setInterval(() => {
        this.getChatList()
      },5000)
      
  },
  methods: {
    sendMsg() {
        if(this.msg == '') {
            Toast.fail('请输入消息内容')
            return
        }
        let params = {
            toId: this.toId,
            msg: this.msg
        }
        passenger.sendMsg(params).then(() => {
            this.msg = ''
            var container = document.getElementsByClassName('msgline')[0];
            container.scrollTop = container.scrollHeight;
            this.getChatList()
        })
    },
    getChatList() {
        
        let params = {
            toId: this.toId
        }
        passenger.getChatList(params).then(res=>{
            this.chatMsgList = res.data.data
            setTimeout(()=>{
                var container = document.getElementsByClassName('msgline')[0];
                container.scrollTop = container.scrollHeight;
                this.isLoading = false
            },200)
            
        })
    },
  }
};
</script>
<style scoped>
.box {width: 100%;background: rgb(9, 160, 151,.5);min-height: 100vh;position: relative;overflow: hidden;}
.list-box{width: 94%;margin-left: 3%;}
.left-chat-box{display: flex;align-items: flex-start;justify-content: flex-start;margin-bottom: 20px;;}
.right-chat-box{display: flex;align-items: flex-start;justify-content: flex-end;margin-bottom: 20px;;}
.headimg {width: 40px;height: 40px;border-radius: 5px;;}
.left-chat-msg {background: #fff;padding: 10px;max-width: 70%;;margin-left: 13px;;border-radius: 7px;;position: relative;}
.left-chat-msg:before {content:"";position: absolute;right: 100%;top: 20px;width: 0;height: 0;border-top: 9px solid transparent;border-right: 8px solid #FFFFFF;border-bottom: 9px solid transparent;transform: translateY(-50%);}
.right-chat-msg {background: #95EC69;padding: 10px;max-width: 70%;;margin-right: 10px;;border-radius: 7px;;position: relative;}
.right-chat-msg:before {content:"";position: absolute;left: 100%;top: 20px;;width: 0;height: 0;border-top: 9px solid transparent;border-left: 8px solid #95EC69;border-bottom: 9px solid transparent;transform: translateY(-50%);}
.msg-content {width:100%;height:60px;padding: 0 10px;position: fixed;bottom: 0;background: #fff;display: flex;align-items: center;}
.texta {border: none;width: 80%;background: #f2f2f2;resize: none;padding: 5px;box-sizing: border-box;border-radius: 5px;}
.send {width: 17%;height:60px;text-align: center;color: #0C9793;line-height: 60px;}
</style>
